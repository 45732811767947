import React, { ComponentProps, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Form, Image, Nav, Row, Tab } from "react-bootstrap";
import { Icon } from "@iconify/react";
import "./LeftSidebar.css";
import PrimaryBtn from "../CommonBtn/PrimaryBtn";
import {
  ChatbotData, GetCurrentChatbot,
  GetFavouriteChatbotList, GetLocalUserChatbots,
  GetPremiumChatbots,
  GetRecentChatbotList, RecentChatbots,
} from "../../Utils/ChatbotUtils";
import User from "../../Models/User";
import PopupModal from "../PopUps/PopUpBase";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { dirtyFlagsSelector, updateDirtyFlags } from "../../Redux/Slices/dirtyFlagsSlice";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import { subDataSelector } from "../../Redux/Slices/subscriptionDataSlice";


function onSidebarToggle() {
  const bodyElement = document.querySelector("body");
  if (bodyElement) {
    bodyElement.classList.toggle("SidebarToggle");
  }
}

interface ComponentProps {
  setOpenSetting: (val: boolean) => void;
  openSetting: boolean;

}

function LeftSidebar({ setOpenSetting, openSetting }: ComponentProps) {
  const location = useLocation();
  const userData = useSelector(userDataSelector);

  // const handleLearnLinkClick = (submenuKey: string) => {
  //   setSubmenuVisibility((prev) => ({
  //     ...prev,
  //     [submenuKey]: !prev[submenuKey],
  //   }));
  // };
  const [submenuVisibility, setSubmenuVisibility] = useState({ submenu1: false });

  const handleLearnLinkClick = (submenuKey) => {
    setSubmenuVisibility((prev) => ({
      ...prev,
      [submenuKey]: !prev[submenuKey],
    }));
  };

  const toggleSidebarOnClick = () => {
    setSubmenuVisibility({ submenu1: false });
  };


  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    const LearnAboutOrbofiMain = document.querySelector(".LearnAboutOrbofiMain");
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !LearnAboutOrbofiMain?.contains(event.target)) {
      setSubmenuVisibility({ submenu1: false });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div ref={sidebarRef} className="sidebar">
        <ul className={`subSidebar-menu ${submenuVisibility.submenu1 ? "visible" : ""}`}>
          <li><Link onClick={toggleSidebarOnClick} to="https://about.orbofi.com/" target="_blank"><lord-icon
            src="https://cdn.lordicon.com/pcllgpqm.json"
            trigger="loop"
          >
          </lord-icon>
            About Orbofi</Link></li>
          <li><Link onClick={toggleSidebarOnClick} to="/pricing">
            <lord-icon
              src="https://cdn.lordicon.com/yiywiexo.json"
              trigger="loop"
            >
            </lord-icon>
            Pricing</Link></li>
          <li><Link onClick={toggleSidebarOnClick} to="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/terms-of-services" target="_blank">
            <lord-icon
              src="https://cdn.lordicon.com/jxhfvths.json"
              trigger="loop"
            >
            </lord-icon>
            Terms & Conditions</Link></li>
          <li><Link onClick={toggleSidebarOnClick} to="https://orbofi.medium.com/" target="_blank">
            <lord-icon
              src="https://cdn.lordicon.com/ivrdfves.json"
              trigger="loop"
            >
            </lord-icon>

            Blog</Link></li>
          <li><Link onClick={toggleSidebarOnClick} to="https://forms.gle/5y7idmy3UQgnFtuB7" target="_blank">
            <lord-icon
              src="https://cdn.lordicon.com/mjdnhmcm.json"
              trigger="loop"
            >
            </lord-icon>
            Contact Us</Link></li>
          <li><Link onClick={toggleSidebarOnClick} to="javascript:void(0)">
            <lord-icon
              src="https://forms.gle/5y7idmy3UQgnFtuB7"
              trigger="loop"
            >
            </lord-icon>
            Community</Link></li>
          <li>
            <Link to="https://x.com/Orbofi?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank"><Icon icon="ri:twitter-x-fill" /></Link>
            <Link to="https://www.linkedin.com/company/orbofi" target="_blank"><Icon icon="ri:linkedin-fill" /></Link>
            <Link to="https://t.me/orbofi" target="_blank"><Icon icon="hugeicons:telegram" /></Link>
            <Link to="https://discord.com/invite/orbofi" target="_blank"><Icon icon="ri:discord-fill" /></Link>
          </li>
        </ul >
      </div>
      <div className={`leftSidebar ${location.pathname.toLowerCase().split("/")[1] === ("chat") ? "ChatLeftSidebar" : ""}`}>
        <div className="chat-sidebar-show">
          <Link className="header-menu-icon" onClick={onSidebarToggle} to="javascript:void(0)"><Icon icon="ic:round-menu" /></Link>
          <Link className="SidebarLogo" to="/"><Image src="/images/logo.png" /></Link>
          <Link to="/" className="nav-logo lightlogo"><Image src="/images/logo-light.png" /></Link>
        </div>
        <div className="sidebarContent">
          <hr className=' sidebar-hr' />
          <SidebarLinks />
          <SidebarCreations />
          <SidebarBottom openSetting={openSetting} setOpenSetting={setOpenSetting} handleLearnLinkClick={handleLearnLinkClick} submenuVisibility={submenuVisibility} />

        </div>
      </div>
      <MobileSidebar openSetting={openSetting} setOpenSetting={setOpenSetting} />
      <PopupModal show={openSetting} handleClose={() => setOpenSetting(false)} className="default-popup login-popup setting-popup" backdrop={undefined}>
        <Link to="javascript:void(0)" className="CloseBtn" onClick={() => setOpenSetting(false)}>
          <Icon icon="solar:close-circle-linear" />
        </Link>
        <div className="OpenSettingMain">
          <h5>Setting</h5>
          <hr />
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column c-tabs setting-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="first">General</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="setting-sidebar-main">
                      <h6><small>Change Email <span>{userData?.Email}</span></small>

                      </h6>
                      <h6><small>Connected Account
                        <span className="linked-account">
                          {userData?.Platform === "Google" ? <i><Icon icon="devicon:google" /></i> : userData?.Platform === "Facebook" ? <i><Icon icon="devicon:facebook" style={{ color: "#1877f2" }} /></i> : userData?.Platform === "Discord" ? <i><Icon icon="skill-icons:discord" /></i> : userData?.Platform === "Twitter" ? <i><Icon icon="fa6-brands:square-x-twitter" style={{ color: "#fff" }} /></i> : ""}
                        </span></small>
                      </h6>

                      <h6 className="ModelSetting">
                        <small>Model
                          <ul >
                            <li><p>LLama</p>  <Link className="active" to="javascript:void(0)">Selected</Link></li>
                            <li><p>Gpt4-o</p>  <span><Link className="upgrade" onClick={() => setOpenSetting(false)} to="/pricing">Upgrade</Link> <Link className="btn-red d-none" to="javascript:void(0)">Cancel</Link></span></li>
                            <li><p>Claude 3.1</p>  <span><Link className="upgrade" onClick={() => setOpenSetting(false)} to="/pricing">Upgrade</Link> <Link className="btn-red d-none" to="javascript:void(0)">Cancel</Link></span></li>
                          </ul>
                        </small>
                      </h6>

                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="setting-sidebar-main">
                      <h6><small>Model
                        <span>
                          <Form.Group className='c-form-control'>
                            <div className='input-icon'>
                              <i><Icon icon="ion:grid-outline" /></i>
                              <Form.Select aria-label={t("default_select_example")}>
                                <option className='form-option'>{t("Lama 2")}</option>
                                <option className='form-option' value="1">{t("one")}</option>
                                <option className='form-option' value="2">{t("two")}</option>
                                <option className='form-option' value="3">{t("three")}</option>
                              </Form.Select>
                            </div>
                          </Form.Group>
                        </span></small> <Link to="javascript:void(0)" className="btn-border-white">Update</Link></h6>
                      <h6><small>Delete <span>My account</span></small> <Link to="javascript:void(0)" className="btn-border-white btn-red">Delete</Link></h6>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </div>
      </PopupModal>
    </>
  );

}

export default LeftSidebar;

const toggleSidebarOnClick = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 1430) {
    onSidebarToggle();
  }
};
function SidebarLinks(): JSX.Element {
  const [activeLink, setActiveLink] = useState("/");


  useEffect(() => {
    // Retrieve active link from local storage
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  const handleItemClick = (link) => {
    setActiveLink(link);
    // Store active link in local storage
    localStorage.setItem("activeLink", link);
  };
  return (
    <>
      <ul className="SidebarMenuList cp-2">
        <li className={activeLink === "/" ? "active" : ""} onClick={() => handleItemClick("/")}>
          <Link to="/" onClick={toggleSidebarOnClick}>
            <i className="shome-icon"><lord-icon
              src="https://cdn.lordicon.com/epietrpn.json"
              trigger="loop"
            >
            </lord-icon></i>
            <span>{t("home")}</span>
          </Link>
        </li>
        <li className={activeLink === "/explore" ? "active" : ""} onClick={() => handleItemClick("/explore")}>
          <Link to="/explore" onClick={toggleSidebarOnClick}>
            <i className="sexplore-icon"><lord-icon
              src="https://cdn.lordicon.com/xahuqqcs.json"
              trigger="loop"
            >
            </lord-icon></i>
            <span>{t("explore")}</span>
          </Link>
        </li>

      </ul>
      <hr />
      <div className="csViewall cp-2">
        <p>{t("your_ais")}</p>

      </div>
    </>
  );
}


function SidebarCreations(): JSX.Element {
  const [userChatbots, setUserChatbots] = useState<ChatbotData[]>([]);
  const [favouriteChatbots, setFavouriteChatbots] = useState<ChatbotData[]>([]);
  const [recentChatbots, setRecentChatbots] = useState<RecentChatbots[]>([]);
  const [currentChatbot, setCurrentChatbot] = useState<RecentChatbots>();
  const dirtyFlags = useSelector(dirtyFlagsSelector);
  const dispatch = useDispatch();
  const userData: User = useSelector(userDataSelector);
  const subData = useSelector(subDataSelector);

  useEffect(() => {
    if (dirtyFlags.UserChatbotsDirty) {
      if (userData?.UserId > 0) {
        GetLocalUserChatbots().then((chatbots) => {
          dispatch(updateDirtyFlags({ UserChatbotsDirty: false }));
          setUserChatbots(chatbots.reverse());
        });
      }
      else {
        setUserChatbots([]);
      }
    }
  }, [userData, dirtyFlags.UserChatbotsDirty]);

  useEffect(() => {
    if (dirtyFlags.FavouriteChatbotsDirty) {
      if (userData?.UserId > 0) {
        GetFavouriteChatbotList().then((chatbots) => {
          dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: false }));
          setFavouriteChatbots(chatbots?.reverse());
        });
      }
      else {
        setFavouriteChatbots([]);
      }
    }
  }, [dirtyFlags.FavouriteChatbotsDirty]);

  useEffect(() => {
    if (dirtyFlags.RecentChatbotsDirty) {
      if (userData?.UserId > 0) {
        GetRecentChatbotList().then((chatbots) => {
          dispatch(updateDirtyFlags({ RecentChatbotsDirty: false }));
          setRecentChatbots(chatbots.reverse());
        });
      }
      else {
        setRecentChatbots([]);
      }
    }
  }, [dirtyFlags.RecentChatbotsDirty]);

  useEffect(() => {
    if (dirtyFlags.CurrentChatbotDirty) {
      if (userData?.UserId > 0) {
        GetCurrentChatbot().then((chatbot) => {
          dispatch(updateDirtyFlags({ CurrentChatbotDirty: false }));
          setCurrentChatbot(chatbot);
        }
        );
      }
      else {
        setCurrentChatbot(undefined);
      }
    }
  }, [dirtyFlags.CurrentChatbotDirty]);
  const [premiumChatbots, setPremiumChatbots] = useState<ChatbotData[]>([]);

  useEffect(() => {
    GetPremiumChatbots().then((chatbots) => { setPremiumChatbots(chatbots); });
  }, []);

  return (
    <div className="cp-2 sidebarTabs">
      <Tab.Container id="left-tabs-example" defaultActiveKey="Chatbots">
        <Nav variant="pills" className="cs-tabs SibeBartabs">
          <Nav.Item>
            <Nav.Link eventKey="Chatbots"><i><Icon icon="lucide:zap" /></i> {t("chatbots")}</Nav.Link>

          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Historys"><i><Icon icon="lucide:zap" /></i> {t("History")}</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="Chatbots">
            <div className="sidebarTabsInfo">
              <ul className="historymain">
                {subData?.SubscriptionLevel === 3 && premiumChatbots.filter(chatbot => chatbot.id === 1).map((chatbot) => (
                  <li key={chatbot.chatbot_id}>
                    <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} onClick={toggleSidebarOnClick}>
                      <span><Image src={chatbot.img} /></span>
                      <small>{chatbot.name}</small>
                      <i><Icon icon="material-symbols:star" /></i>
                    </a>
                </li>
                ))}
                {localStorage.getItem("oauthToken") && favouriteChatbots?.map((chatbot) => (
                  <li key={chatbot.chatbot_id}>
                    {chatbot.user_created ? (
                      <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} onClick={toggleSidebarOnClick}>
                        <span><Image src={chatbot.img} /></span>
                        <small>{chatbot.name}</small>
                        <i><Icon icon="material-symbols:star" /></i>
                      </a>
                    ) : (
                      <a href={`/chat/${chatbot.string_id}`} onClick={toggleSidebarOnClick}>
                        <span><Image src={chatbot.img} /></span>
                        <small>{chatbot.name}</small>
                        <i><Icon icon="material-symbols:star" /></i>
                      </a>
                    )}
                  </li>
                ))}
              </ul>
              <ul >
                {localStorage.getItem("oauthToken") && userChatbots?.map((chatbot) => (
                  <li key={chatbot.id}>
                    <a href={`/chat/${chatbot.name}?id=${chatbot.id}`} onClick={toggleSidebarOnClick}>
                      <span><Image src={chatbot.img} /></span>
                      <small>{chatbot.name}</small>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="Historys">
            <div className="sidebarTabsInfo">

              <ul className="historymain">
                {localStorage.getItem("oauthToken") && currentChatbot && !recentChatbots?.includes(currentChatbot) && (
                  <li key={currentChatbot.chatbot_id}>
                    <div className="historydetails d-flex">
                      <span><Image src={currentChatbot.img} /></span>
                      <div className="">
                        {currentChatbot.user_created ?
                          <a href={`/chat/${currentChatbot.name}?id=${currentChatbot.chatbot_id}`} onClick={toggleSidebarOnClick}>{currentChatbot.name}</a>
                          :
                          <a href={`/chat/${currentChatbot.string_id}`} onClick={toggleSidebarOnClick}>{currentChatbot.name}</a>
                        }
                        <p>{currentChatbot.description?.slice(0, 50)}</p>
                      </div>
                    </div>
                  </li>
                )}
                {localStorage.getItem("oauthToken") && recentChatbots?.map((chatbot) => (
                  <li key={chatbot.chatbot_id}>
                    <div className="historydetails d-flex">
                      <span><Image src={chatbot.img} /></span>
                      <div className="">
                        {chatbot.user_created ?
                          <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} onClick={toggleSidebarOnClick}>{chatbot.name}</a>
                          :
                          <a href={`/chat/${chatbot.string_id}`} onClick={toggleSidebarOnClick}>{chatbot.name}</a>
                        }
                        <p>{chatbot.description?.slice(0, 50)}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

    </div>
  );
}

function SidebarBottom({ openSetting, setOpenSetting, handleLearnLinkClick, submenuVisibility }): JSX.Element {
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchToggle = () => {
    setIsChecked(!isChecked);
    if (!document.body.classList.contains("light-modee")) {
      document.body.classList.add("light-modee");
    } else {
      document.body.classList.remove("light-modee");
    }
  };

  const dispatch = useDispatch();
  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <div className="SidebarBottom cp-2">
      <div className="creatediscoverbtn mb-1">
        {localStorage.getItem("oauthToken") ?
          <>
            <Link to="/createaicompanion" onClick={toggleSidebarOnClick}>
              <Icon icon="oui:ml-create-advanced-job" />
              <span>Create</span>
            </Link>
          </>
          :
          <>
            <Link to="/" onClick={OnOpenLoginPopup}>
              <Icon icon="oui:ml-create-advanced-job" />
              <span>Create</span>
            </Link>

          </>
        }


        <Link to="/explore" onClick={toggleSidebarOnClick}>
          <Icon icon="iconamoon:discover-thin" />
          <span>Discover</span>
        </Link>

      </div>
      <div className="SidebarUpgradOrbox">
        <span><Icon icon="heroicons:sparkles-solid" /></span>
        <h6>Power up your AI companions</h6>
        <p>Start from only $9,99/m</p>
        <PrimaryBtn child={<>Upgrade <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={"SidebarUpgradOrbox-Btn"} linkName={"/pricing"} onClick={toggleSidebarOnClick} />
      </div>
      <div className="leftsidemenu">
        <Link className="orboxBuyLink" to="/pricing" onClick={toggleSidebarOnClick}><i><Image src="/images/logo-coin.svg" /></i> <small>Purchase Orbox</small></Link>
        <div className={`LearnAboutOrbofiMain ${!submenuVisibility.submenu1 ? "active" : ""}`} >
          <Link to="javascript:void(0)" className="LearnAboutLink" onClick={(event) => {
            handleLearnLinkClick("submenu1");
          }}>
            <i><Image src="/assets/icons/orbofi-icon-white.svg" /></i>
            <small>Learn More About us</small>
            <Icon icon="ph:caret-right" width="16px" height="16px" />
          </Link>
        </div>
        <Link to="https://forms.gle/5y7idmy3UQgnFtuB7" target="_blank" onClick={toggleSidebarOnClick}><i><Icon icon="material-symbols:help-outline" /></i> <small>Help</small></Link>
        <div className="sidebarSettingWrap">
          {localStorage.getItem("oauthToken") ?
            <>
              <Link to="javascript:void(0)" onClick={() => setOpenSetting(true)}><i><Icon icon="lets-icons:setting-line" /></i> <small>Settings</small></Link>
            </>
            :
            <>
              <Link to="javascript:void(0)" onClick={OnOpenLoginPopup}><i><Icon icon="lets-icons:setting-line" /></i> <small>Settings</small></Link>



            </>
          }


        </div>
      </div>
    </div>
  );
}


// TODO: This could be moved to a separate file
function MobileSidebar({ openSetting, setOpenSetting }): JSX.Element {

  const [userChatbots, setUserChatbots] = useState<ChatbotData[]>([]);
  const userData: User = useSelector(userDataSelector);

  useEffect(() => {
    if (userData?.UserId > 0) {
      GetLocalUserChatbots().then((chatbots) => { setUserChatbots(chatbots); });
    }
    else {
      setUserChatbots([]);
    }
  }, [userData]);

  const [favouriteChatbots, setFavouriteChatbots] = useState<ChatbotData[]>([]);
  const [recentChatbots, setRecentChatbots] = useState<RecentChatbots[]>([]);
  const [currentChatbot, setCurrentChatbot] = useState<RecentChatbots>();

  const dirtyFlags = useSelector(dirtyFlagsSelector);





  useEffect(() => {
    if (dirtyFlags.FavouriteChatbotsDirty) {
      if (userData?.UserId > 0) {
        GetFavouriteChatbotList().then((chatbots) => {
          dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: false }));
          setFavouriteChatbots(chatbots);
        });
      }
      else {
        setFavouriteChatbots([]);
      }
    }
  }, [dirtyFlags.FavouriteChatbotsDirty]);

  useEffect(() => {
    if (dirtyFlags.RecentChatbotsDirty) {
      if (userData?.UserId > 0) {
        GetRecentChatbotList().then((chatbots) => {
          dispatch(updateDirtyFlags({ RecentChatbotsDirty: false }));
          setRecentChatbots(chatbots);
        });
      }
      else {
        setRecentChatbots([]);
      }
    }
  }, [dirtyFlags.RecentChatbotsDirty]);

  useEffect(() => {
    if (dirtyFlags.CurrentChatbotDirty) {
      if (userData?.UserId > 0) {
        GetCurrentChatbot().then((chatbot) => {
          dispatch(updateDirtyFlags({ CurrentChatbotDirty: false }));
          setCurrentChatbot(chatbot);
        });
      }
      else {
        setCurrentChatbot(undefined);
      }
    }
  }, [dirtyFlags.CurrentChatbotDirty]);
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchToggle = () => {
    setIsChecked(!isChecked);
    if (!document.body.classList.contains("light-modee")) {
      document.body.classList.add("light-modee");
    } else {
      document.body.classList.remove("light-modee");
    }
  };
  const dispatch = useDispatch();
  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    // <div className={`mobile-leftSidebar ${location.pathname.toLowerCase().startsWith("/chat") ? "ChatLeftSidebar" : ""}`}>
    <div className={`mobile-leftSidebar ${location.pathname.toLowerCase().split("/")[1] === ("chat") ? "ChatLeftSidebar" : ""}`}>
      <Link className="header-menu-icon SidebarLogo m-0 text-center" onClick={onSidebarToggle} to="javascript:void(0)"><Icon icon="ic:round-menu" /></Link>
      <hr className="m-0 sidebar-hr" style={{ border: "1px solid #1c1c1c", }} />
      <div className="sidebarContent">

        {localStorage.getItem("oauthToken") ?
          <>
            <PrimaryBtn child={<><i><Icon icon="heroicons:sparkles-solid" /></i></>} className={"mobile-leftSidebar-createbtn mt-3"} linkName={"/createaicompanion"} onClick={() => { }} />

          </>
          :
          <>
            <PrimaryBtn child={<><i><Icon icon="heroicons:sparkles-solid" /></i></>} className={"mobile-leftSidebar-createbtn mt-3"} linkName={"/"} onClick={OnOpenLoginPopup} />



          </>
        }


        <ul className="SidebarMenuList">
          <li>
            <Link to="/" onClick={toggleSidebarOnClick}>
              <i className="shome-icon"><lord-icon
                src="https://cdn.lordicon.com/epietrpn.json"
                trigger="loop"
              >
              </lord-icon></i>
            </Link>
          </li>
          <li>
            <Link to="/explore" onClick={toggleSidebarOnClick}>
              <i className="sexplore-icon"><lord-icon
                src="https://cdn.lordicon.com/xahuqqcs.json"
                trigger="loop"
              >
              </lord-icon></i>
            </Link>
          </li>

        </ul>
        <hr />
        <div className="csViewall text-center">
          <p>{t("your_ais")}</p>
        </div>
        <div className="cpm-2">
          <Tab.Container id="left-tabs-example" defaultActiveKey="Chatbots">
            <Nav variant="pills" className="cs-tabs SibeBartabs flex-column">
              <Nav.Item>
                <Nav.Link eventKey="Chatbots"><Icon icon="lucide:zap" /> </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Factories"><Icon icon="healthicons:h" /> </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="Chatbots">
                <div className="sidebarTabsInfo">
                  <ul className="historymain">
                    {localStorage.getItem("oauthToken") && favouriteChatbots?.map((chatbot) => (
                      <li key={chatbot.chatbot_id}>
                        {chatbot.user_created ? (
                          <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} onClick={toggleSidebarOnClick}>
                            <span><Image src={chatbot.img} /></span>
                            {/* <small>{chatbot.name}</small> */}
                            <i><Icon icon="material-symbols:star" /></i>
                          </a>
                        ) : (
                          <a href={`/chat/${chatbot.string_id}`} onClick={toggleSidebarOnClick}>
                            <span><Image src={chatbot.img} /></span>
                            {/* <small>{chatbot.name}</small> */}
                            <i><Icon icon="material-symbols:star" /></i>
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {localStorage.getItem("oauthToken") && userChatbots?.map((chatbot) => (
                      <li key={chatbot.id}>

                        <a href={`/chat/${chatbot.name}?id=${chatbot.id}`} onClick={toggleSidebarOnClick}>
                          <span><Image src={chatbot.img} /></span>
                          {/* <small>{chatbot.name}</small> */}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Factories">
                <div className="sidebarTabsInfo">

                  <ul className="historymain">
                    {localStorage.getItem("oauthToken") && currentChatbot && !recentChatbots?.includes(currentChatbot) && (
                      <li key={currentChatbot.chatbot_id}>
                        <div className="historydetails d-flex">
                          <div className="">
                            {currentChatbot.user_created ?
                              <a href={`/chat/${currentChatbot.name}?id=${currentChatbot.chatbot_id}`} onClick={toggleSidebarOnClick}><span><Image src={currentChatbot.img} /></span></a>
                              :
                              <a href={`/chat/${currentChatbot.string_id}`} onClick={toggleSidebarOnClick}><span><Image src={currentChatbot.img} /></span></a>
                            }
                            {/* <p>{currentChatbot.description?.slice(0, 50)}</p> */}
                          </div>
                        </div>
                      </li>
                    )}
                    {localStorage.getItem("oauthToken") && recentChatbots?.map((chatbot) => (
                      <li key={chatbot.chatbot_id}>
                        <div className="historydetails d-flex">
                          {/* <span><Image src={chatbot.img} /></span> */}
                          {chatbot.user_created ?
                            <Link to={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`}
                              onClick={toggleSidebarOnClick}><span><Image src={chatbot.img} /></span></Link>
                            :
                            <Link to={`/chat/${chatbot.string_id}`} onClick={toggleSidebarOnClick}><span><Image src={chatbot.img} /></span></Link>
                          }
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className="SidebarBottom">
          <Link className="orboxBuyLink" to="/pricing" onClick={toggleSidebarOnClick}><i><Image src="/images/logo-coin.svg" /></i></Link>
          <Link to="https://orbofi.canny.io/features-request" target="_blank" ><i><Icon icon="material-symbols:help-outline" /></i> </Link>
          <div className="sidebarSettingWrap">
            {/* <Link to="javascript:void(0)" onClick={() => setOpenSetting(true)}><i><Icon icon="lets-icons:setting-line" /></i> </Link> */}


            {localStorage.getItem("oauthToken") ?
              <>
                <Link to="javascript:void(0)" onClick={() => setOpenSetting(true)} ><i><Icon icon="lets-icons:setting-line" /></i></Link>
              </>
              :
              <>


                <Link to="javascript:void(0)" onClick={OnOpenLoginPopup} ><i><Icon icon="lets-icons:setting-line" /></i></Link>

              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}