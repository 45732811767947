import { Icon } from "@iconify/react";
import { Button, Col, Dropdown, Form, Image, Nav, Row, Tab } from "react-bootstrap";
import PrimaryBtn from "../../Components/CommonBtn/PrimaryBtn";
import { ProgressIndicator } from "../../Components/ProgressIndicator/ProgressIndicator";
import { Link } from "react-router-dom";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import PopupModal from "../../Components/PopUps/PopUpBase";
import { Select } from 'react-select';
import {RegisterOrboxUse, RUNPOD_BASE_URL, RUNPOD_ROMANIAN_SERVER,} from '../../Utils/Utils';
const allImages = [];

function UploadAndGenerateImage(props: any) {
    const [prompt, setPrompt] = useState("");
    // const [props.selectedGeneratedImage, setSelectedGeneratedImage] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [isCloning, setIsCloning] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [filename, setFilename] = useState("");
    const [audioUrl, setAudioUrl] = useState("");
    const [allImagesGens, setAllImagesGens] = useState([]);
    const handlePromptChange = (event) => {
        setPrompt(event.target.value);
    };


    const fetchImage2 = async () => {
        try {
            const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/generate`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
                body: JSON.stringify({ prompt }), // Send the prompt in the request body
              });
          
              if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
              }
          
              const data = await response.json(); // Parse the response JSON
              console.log(data);
              // return data["output"]['image_url']; // Extracting the first image from the response
              return data["output"]; // Extracting the first image from the response
        } catch (error) {
            console.error("Error in fetchImage:", error);
            return null; // Return null in case of an error
        }
    };

    const fetchGeneration = async () => {
        if(isFetching) return;
        setIsFetching(true);
        let promises;
        promises = Array(4).fill().map(() => fetchImage2());
        const results = await Promise.all(promises);
        props.setSelectedGeneratedImage(results[0]);
        setSelectedImage(results[0]);
        setAllImagesGens(results);
        setIsFetching(false);
        if (selectedOption === 1) {
            // Generate Image action

            // Assuming image generation takes some time, set imageGenerated to true when generation is complete
            setImageGenerated(true);
        } else {
            // Upload Image action

            // Assuming image upload takes some time, set imageGenerated to true when upload is complete
            setImageGenerated(true);
        }
    };


    const generateImage = () => {
        const OPENAI_API_KEY = "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"; // Replace this with your actual API key
        axios.post("https://api.openai.com/v1/images/generations", {
            model: "dall-e-3",
            prompt: prompt,
            n: 1,
            size: "1024x1024"
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${OPENAI_API_KEY}`
            }
        })
            .then(response => {
                const imageUrl = response.data.data[0].url; // Adjust this according to the actual response structure
                setImageSrc(imageUrl);
            })
            .catch(error => {
                console.error("Error generating image:", error);
            });
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageGenerated, setImageGenerated] = useState(false);

    useEffect(() => {
        setSelectedImage(props.selectedGeneratedImage)
    }, [])
    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedImage(reader.result); // Set the selected image URL
        };

        if (file) {
            reader.readAsDataURL(file); // Read the selected image file
        }
    };


    const [selectedOption, setSelectedOption] = useState(1);
    const handleSelectChange = (e) => {
        setSelectedOption(parseInt(e.target.value));
    };
    const [image, setImage] = useState(null);

    // Function to handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setSelectedImage(event.target.result);
                props.setSelectedGeneratedImage(event.target.result)
            };
            setImageGenerated(true);
            reader.readAsDataURL(file);
        }
    };
    const [GenerateImage, setGenerateImage] = useState(false);
    return (
        <>
            <Dropdown className="UploadandGenretateMain">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                    <div className="UploadandGenretateMainImage">
                        <Image src={selectedImage ? selectedImage : "/images/dummy-image.png"} />
                    </div>
                    <i><Icon icon="mage:edit-pen-fill" /></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-end">
                    <Dropdown.Item href="javascript:void(0)">
                        <input type="file" name="uploadImage" className="uploadImageInput" id="uploadImage" onChange={handleFileUpload} accept="image/png, image/jpeg" />
                        <i><Icon icon="mdi:image-plus-outline" /></i> Upload Image</Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)" onClick={() => setGenerateImage(true)}><i><Icon icon="streamline:ai-generate-portrait-image-spark" /></i> Generate Image</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Row className='justify-content-between '>
                <Col lg={12}>
                    <Form>
                        <Form.Group className="c-form-control aisteptwo">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="Generate Image">

                                <Tab.Content>
                                    <Tab.Pane eventKey="Upload Image">
                                        <Form.Group className='c-form-control mb-3'>
                                            <Form.Label>Upload Image</Form.Label>
                                            <label htmlFor="uploadImage" className="UploadImageBtn-Main">
                                                <input type="file" name="uploadImage" id="uploadImage" onChange={handleFileUpload} accept="image/png, image/jpeg" />
                                                <div className="UploadVoiceBox">
                                                    <span><Icon icon="solar:upload-bold" /></span>
                                                    <h6>Click to upload a file or drag and drop <small>PNG, JPG, JPEG, up to 10MB each</small></h6>


                                                    {/* <Link onClick={fetchGeneration} className={`btn-primary generateimage ${image ? 'showbtn' : ''} mt-3`}>{t("generate")}</Link> */}
                                                </div>

                                            </label>
                                            {/* Display the preview of the uploaded image */}

                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>

                        </Form.Group>
                    </Form>


                </Col>
            </Row>
            <PopupModal
                show={GenerateImage}
                handleClose={setGenerateImage}
                className="default-popup login-popup setting-popup"
                backdrop={undefined}
            >
                <Link
                    to="javascript:void(0)"
                    className="CloseBtn"
                    onClick={() => setGenerateImage(false)}
                >
                    <Icon icon="solar:close-circle-linear" />
                </Link>
                {allImagesGens.length > 0 ? (
                    <div> <ul className='CompanionSubImage'>

                        {allImagesGens.map((imageSrc, index) => (
                            <li key={index}>
                                <Link to="javascript:void(0)" className={`addimgsub ${selectedImage === imageSrc ? "active": ""}` } onClick={() => { setSelectedImage(imageSrc); props.setSelectedGeneratedImage(imageSrc); }}>
                                    <Image src={imageSrc} />
                                    <Icon icon="ph:plus-bold" width="20px" height="20px" />
                                </Link>
                            </li>
                        ))}

                    </ul></div>
                ) : ''}
                <Form.Label>{t("prompt")}</Form.Label>
                <div className="Chat-Prompt-Textarea generateimage">
                    <Form.Control as="textarea" rows={4} placeholder={t("generate a profile picture your AI companion with AI. it could be a person/character, or even a logo.")} value={prompt} onChange={handlePromptChange} style={{ fontSize: "14px" }} />
                </div>
                <div className="Chat-Prompt-Wrap">
                    <Button variant="" disabled={!selectedImage} onClick={() => setGenerateImage(false)} className="btn-primary">{t("Select")} <i><Icon icon="material-symbols:select-check-box-rounded" /></i></Button>
                    <Link onClick={fetchGeneration} className="btn-primary btn-generate-loader">{isFetching ? <span><lord-icon src="https://cdn.lordicon.com/rqptwppx.json" trigger="loop" stroke="bold" state="loop-cycle" colors="primary:#ffffff,secondary:#ffffff"></lord-icon></span> : <>{t("Generate")} <i><Icon icon="fluent:pen-sparkle-16-filled" /></i></>} </Link>
                </div>
            </PopupModal>
        </>
    );
}

export default UploadAndGenerateImage;
