import { Breadcrumb, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import WizardForm from "react-wizard-form";
import CreateAICompanionStepOne from "./CreateAICompanionStepOne";
import CreateAICompanionStepTwo from "./CreateAICompanionStepTwo";
import CreateAICompanionStepThree from "./CreateAICompanionStepThree";
import "./CreateAICompanionPage.css";
import "./CreateAICompanionNew.css"
import CreateAICompanionStepThreeLite from "./CreateAICompanionStepThreeLite";
import CreateAICompanionStepTwoStep from "./CreateAICompanionStepTwoStep";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useState } from "react";


function CreateAICompanionPage() {
const [activeStep, setActiveStep] = useState(0)

  return (
    <div className="MainContent page-bg d-margin padding-16 createPageMainContent">
      <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
      <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
      <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
      <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
      <ProgressBar
        percent={activeStep}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} width="30"></div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} width="30"></div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} width="30"></div>
          )}
        </Step>
      </ProgressBar>
        <WizardForm>
          <CreateAICompanionStepOne setActiveStep={setActiveStep}/>
          <CreateAICompanionStepTwoStep setActiveStep={setActiveStep}/>
          {/* <CreateAICompanionStepTwo /> */}
          <CreateAICompanionStepThreeLite setActiveStep={setActiveStep}/>
        </WizardForm>
    </div>
  );
}

export default CreateAICompanionPage;
