import { Image } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import "./AllTimeFavBanner.css";
import { t } from "i18next";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import { useDispatch } from "react-redux";

function AllTimeFavBannerHome() {
  const dispatch = useDispatch();

  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    <div className="AllTimeFavBanner-Main">
      {/* <h5>{t("favorites")}</h5> */}
      <h4>{t("dont_miss_our_alltime_favorites")}</h4>
      <p>{t("Create_any_kind_of_voice-powered_AI_agents_for_any_kind_of_purpose")}</p>
      <div className="AllTimeFavBannerProfile">
        {/* <span><Image src="https://storage.googleapis.com/factories_orbofi/green-2024-01-18_16-40-44.png" /></span>
        <div>
          <h6>{t("danny_trejo")}</h6>
          <small>{t("you_are_danny_trejo_replica")}</small>
        </div> */}
        {localStorage.getItem("oauthToken") ?
          <>
            <Link to="/createaicompanion">{t("try_it_now")} <i><Icon icon="ep:top-right" /></i></Link>
          </>
          :
          <>
            <Link to="/">{t("try_it_now")} <i><Icon icon="ep:top-right" onClick={OnOpenLoginPopup} /></i></Link>
          </>
        }

      </div>
    </div>
  );
}

export default AllTimeFavBannerHome;
